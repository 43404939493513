@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
    @apply font-bold py-2 px-4 rounded;
}

.color-bg-blue {
    background-color: theme('colors.blue.600');

}
.color-bg-orange {
    background-color: theme('colors.blue.500');
}